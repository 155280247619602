var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.assessment
    ? _c(
        "div",
        { staticClass: "assessment" },
        [
          _vm.showModal
            ? _c(
                "modal",
                {
                  attrs: {
                    "click-handler": _vm.completeAssessment,
                    "text-button": "OK"
                  },
                  on: {
                    close: function($event) {
                      _vm.showModal = false
                    }
                  }
                },
                [
                  _c("div", { staticClass: "message row" }, [
                    _c("span", [_vm._v(_vm._s(_vm.submitMessage))])
                  ])
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "assessment-banner row" }, [
            _c("img", {
              staticClass: "info-icon",
              attrs: { src: _vm.infoIcon },
              on: { click: _vm.goToTeacherPage }
            }),
            _c("img", {
              staticClass: "lesson-image",
              attrs: { src: _vm.image }
            }),
            _c("div", { staticClass: "column" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.lesson.title || "",
                          expression: "lesson.title || ''"
                        }
                      ],
                      staticClass: "assessment-title"
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("assessment")) +
                          ": " +
                          _vm._s(_vm.lesson.title) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm.assessment && _vm.assessment.audio
                    ? _c("AudioToggle", {
                        attrs: {
                          audio: _vm.assessment.audio,
                          "white-background": true
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c("div", { staticClass: "assessment-directions" }, [
                _c("span", [_vm._v(_vm._s(_vm.$t("assessmentDirections")))]),
                _c("p", { staticClass: "custom-scrollbar" }, [
                  _vm._v(_vm._s(_vm.assessment.directions))
                ])
              ])
            ])
          ]),
          _vm.assessmentSections.length == 0 && !_vm.isLoadingInformation
            ? _c("span", { staticClass: "centered" }, [
                _vm._v(_vm._s(_vm.$t("noContentToDisplayMessage")))
              ])
            : [
                _vm.questionDirection
                  ? _c(
                      "div",
                      { staticClass: "question-direction" },
                      [
                        _c("h2", [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.nameFilter(
                                  _vm.questionDirection.questionType
                                ) +
                                  " " +
                                  (_vm.locale === "en" ? "directions" : "")
                              ) +
                              "\n      "
                          )
                        ]),
                        _c("p", [
                          _vm._v(_vm._s(_vm.questionDirection.content))
                        ]),
                        _vm.questionDirection.audio
                          ? _c("audio-toggle", {
                              key: _vm.questionDirection.audio.id,
                              attrs: { audio: _vm.questionDirection.audio }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _c(_vm.currentSection, { tag: "component" }),
                _c("div", { staticClass: "row" }, [
                  _vm.displayAssessmentScore
                    ? _c("span", { staticClass: "legend" }, [
                        _vm._v(_vm._s(_vm.getAssessmentScore()))
                      ])
                    : _vm._e()
                ]),
                _vm.lesson
                  ? _c(
                      "button",
                      {
                        staticClass: "btn round",
                        class: _vm.isBackButtonDisabled
                          ? "secondary"
                          : "primary",
                        attrs: { disabled: _vm.isBackButtonDisabled },
                        on: {
                          click: function($event) {
                            _vm.MoveToPreviousAssessment()
                          }
                        }
                      },
                      [_vm._v("\n      " + _vm._s(_vm.$t("back")) + "\n    ")]
                    )
                  : _vm._e(),
                _vm.lesson
                  ? _c(
                      "button",
                      {
                        staticClass: "btn round",
                        class: _vm.isSubmitButtonDisabled
                          ? "secondary"
                          : "primary",
                        attrs: { disabled: _vm.isSubmitButtonDisabled },
                        on: {
                          click: function($event) {
                            _vm.MoveToNextAssessment()
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n      " + _vm._s(_vm.nextButtonText) + "\n    "
                        )
                      ]
                    )
                  : _vm._e()
              ]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }